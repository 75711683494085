
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import reports from "@/core/data/report";
import { IReport } from "@/core/data/report";
import Swal from "sweetalert2/dist/sweetalert2.js";
//import DatePicker from "vue-datepicker-next";
import { useStore } from "vuex";
//import "vue-datepicker-next/index.css";
import { Actions } from "@/store/enums/StoreEnums";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    ExportCustomerModal,
    VPagination,
    Datepicker,
    // AddCustomerModal,
  },
  setup() {
    const store = useStore();
    const showTable = ref(false);
    const checkedReport = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "User",
        key: "user",
        sortable: true,
      },
      {
        name: "My Number",
        key: "myNumber",
        sortable: true,
      },
      {
        name: "Number",
        key: "number",
        sortable: true,
      },
      {
        name: "Message",
        key: "message",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "date",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
    ]);
    const date = ref();
    const tableData = ref<Array<IReport>>([]);
    const initCustomers = ref<Array<IReport>>([]);
    const limit = ref(10);
    const total_pages = ref(1);
    const startDate = ref("");
    const endDate = ref("");
    const users = ref([]);
    const selectedUser = ref("");
    const showUserOption = ref(false);
    onMounted(() => {
      const data = localStorage.getItem("userdata");
      var allData = JSON.parse("" + data + "");
      showUserOption.value = allData.type;
      setCurrentPageBreadcrumbs("Report", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      const startDate = new Date(new Date().setDate(new Date().getDate() - 7));
      const endDate = new Date();
      date.value = [startDate, endDate];
      format(date.value);
      getAllUser();
      getReportData(1);
    });

    const format = (date) => {
      var startdate = date[0];
      var enddate = date[1];
      console.log(startdate);
      console.log(enddate);
      let day = startdate.getDate();
      if (day < 10) {
        day = `0${day}`;
      }

      let month = startdate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      const year = startdate.getFullYear();

      let day1 = enddate.getDate();
      if (day1 < 10) {
        day1 = `0${day1}`;
      }

      let month1 = enddate.getMonth() + 1;
      if (month1 < 10) {
        month1 = `0${month1}`;
      }

      const year1 = enddate.getFullYear();
      startDate.value = `${year}-${month}-${day}`;
      endDate.value = `${year1}-${month1}-${day1}`;
      date.value = `${day}/${month}/${year} - ${day1}/${month1}/${year1}`;
      return date.value;
    };
    const exportReport = () => {
      loading.value = true;
      var request = {
        url: `/message/report-export?startDate=${startDate.value}&endDate=${endDate.value}&user=${selectedUser.value}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        //console.log(data);
        downloadFileForContact(data.data, "report_all");
      }, error => {
        loading.value = false;
      });
    };

    const downloadFileForContact = (data, filename = "data") => {
      let csvData = ConvertToCSV(data, [
        "Replay Status",
        "My Number",
        "Number",
        "Group Name",
        "First Name",
        "Last Name",
        "Property Address",
        "Zip",
        "City",
        "State",
        "Message",
        "Date",
        "Status",
      ]);
      // console.log(csvData)
      let blob = new Blob(["\ufeff" + csvData], {
        type: "text/csv;charset=utf-8;",
      });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser =
        navigator.userAgent.indexOf("Safari") != -1 &&
        navigator.userAgent.indexOf("Chrome") == -1;
      if (isSafariBrowser) {
        //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
      loading.value = false;
    };

    const ConvertToCSV = (objArray, headerList) => {
      let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      let str = "";
      let row = "";

      for (let index in headerList) {
        row += headerList[index] + ",";
      }
      row = row.slice(0, -1);
      str += row + "\r\n";
      for (let i = 0; i < array.length; i++) {
        let line: any = "";
        for (let j = 0; j < headerList.length; j++) {
          var data;
          switch (headerList[j]) {
            case "Replay Status":
              data = array[i].contact
                ? array[i].contact.replay_status
                : "0";
              break;
            case "My Number":
              data = array[i].twilio_number ? array[i].twilio_number : "0";
              break;

            case "Number":
              data = array[i].number ? array[i].number : "0";
              break;
            case "Group Name":
              data = array[i].group_id ? array[i].group_id.groupname : "0";
              break;
            case "First Name":
              data = array[i].contact ? array[i].contact.first_name : "0";
              break;
            case "Last Name":
              data = array[i].contact ? array[i].contact.last_name : "0";
              break;
            case "Property Address":
              data = array[i].contact
                ? array[i].contact.property_address
                : "0";
              break;
            case "Zip":
              data = array[i].contact ? array[i].contact.zip : "0";
              break;
            case "City":
              data = array[i].contact ? array[i].contact.city : "0";
              break;
            case "State":
              data = array[i].contact ? array[i].contact.state : "0";
              break;
            case "Message":
              data = array[i].message ? array[i].message.toString() : "0";
              break;
            case "Date":
              data = array[i].createdAt ? array[i].createdAt : "0";
              break;
            case "Status":
              data = array[i].status ? array[i].status : "0";
              break;
            default:
              data = "0";
          }
          data = data
            .toString()
            .replace(/,/g, " ")
            .replace(/\r\n/g, " ")
            .replace(/\n/g, " ");

          if (line == "") {
            line = data;
          } else {
            line += "," + data;
          }
        }
        str += line + "\r\n";
      }
      return str;
    };

    const getReportData = (page) => {
      showTable.value = false;
      var request = {
        url: `/report-data?skip=${page}&limit=${limit.value}&startDate=${startDate.value}&endDate=${endDate.value}&user=${selectedUser.value}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          tableData.value = data.data;
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
          total_pages.value = Math.ceil(data.total / limit.value);
          showTable.value = true;
          console.log("datata", tableData.value);
        }
      });
    };

    const getAllUser = () => {
      var request = {
        url: `/users/all`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        users.value = data.data;
      });
    };

    const deleteFewReport = () => {
      checkedReport.value.forEach((item) => {
        deleteReport(item);
      });
      checkedReport.value.length = 0;
    };

    const deleteReport = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // for (let i = 0; i < tableData.value.length; i++) {
          //   if (tableData.value[i].id === id) {
          //     tableData.value.splice(i, 1);
          //   }
          // }
          Swal.fire("Deleted!", "Report has been deleted.", "success");
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<IReport> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };
    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD");
    };

    const updateHandler = (value) => {
      showTable.value = false;
      getReportData(value);
    };

    const changesdate = (data) => {
      console.log("df", data);
      //countData();
      showTable.value = false;
      setTimeout(() =>{
        getReportData(1);
      }, 500)
    };
    const page = ref(1);
    const loading = ref(false);
    return {
      tableData,
      tableHeader,
      deleteReport,
      search,
      searchItems,
      checkedReport,
      deleteFewReport,
      showTable,
      getReportData,
      dateTime,
      updateHandler,
      limit,
      total_pages,
      changesdate,
      date,
      users,
      format,
      selectedUser,
      exportReport,
      showUserOption,
      page,
      loading
    };
  },
});
